import React, {Component} from "react";
import classnames from "classnames";
import {connect} from "react-redux";
import './Comment.scss'
import PropTypes from "prop-types";
import shortid from "shortid";
import {withTranslation} from "react-i18next";
import TimeAgo from "react-timeago/lib/index";
import {timeagoFormatter} from "../../../i18n";





class _CommentEditor extends Component {

    static propTypes = {
        comment: PropTypes.object,
        isNew: PropTypes.bool,
        handleSubmit: PropTypes.func
    };

    constructor(props) {
        super(props);
        const {comment} = this.props;
        let text = '';
        if (!!comment) {
            text = comment.text
        }
        this.state = {
            ...props,
            text,
            editMode: false,
            dirty: false
        };

    }

    handleChange = (event) => {
        // If the form was untouched
        let untouched = !this.state.dirty;
        this.setState({text: event.target.value, dirty: true});
        if (untouched) {
            this.props.onCommentChange();
        }
    };

    activateEdit = () => {
        this.setState({editMode: true});
    };

    deactivateEdit = () => {
        this.setState({editMode: false});
    };

    onEditFocus = (e) => {
        const temp_value = e.target.value;
        e.target.value = '';
        e.target.value = temp_value
    };

    handleFormSubmit = (event) => {
        const {text: comment} = this.state;
        if (!comment || !comment.length) {
            return;
        }
        const comment_o = {
            _id: shortid.generate(),
            ...this.props.comment,
            text: comment,
            created_at: new Date(),
        };
        this.props.handleSubmit(event, !this.props.isNew ? 'CHANGE_COMMENT' : 'ADD_COMMENT', comment_o);
        if (this.props.isNew) {
            this.setState({text: ''})
        } else {
            this.setState({editMode: false})
        }
    };

    onDelete = (id) => {
        return (event) => {
            this.props.handleSubmit(event, 'DELETE_COMMENT', id);
        }
    };

    render = () => {
        const {text, editMode} = this.state;
        const {isNew, i18n, comment} = this.props;
        let currentLang = i18n.language;
        const formatter = timeagoFormatter(currentLang);
        return (
            <form onSubmit={this.handleFormSubmit}>
                <div className={'comment-creator'}>
                    {editMode &&
                    <>
                        <textarea className={classnames('comment-input', 'form-control')} name="comment"
                                  onChange={this.handleChange}
                                  autoFocus
                                  placeholder={i18n.t("Add a comment for yourself")}
                                  required={true}
                                  value={text}
                                  onFocus={this.onEditFocus}
                        />
                        <input type={'submit'} className={classnames("btn btn-success", 'comment-save')}
                               value={!isNew ? i18n.t('Edit Comment') : i18n.t('Save Comment')}/>
                    </>
                    }
                    {!editMode && <div className={'display-comment'}>
                        <input className={classnames('form-control')} name="comment"
                               placeholder={i18n.t("Add a comment for yourself")}
                               required={true} value={text}
                               onFocus={this.activateEdit}
                               onChange={() => {
                               }}
                        />
                        {!!comment &&
                        <div className={'comment-meta'}>
                            <span> {i18n.t("Added")} <TimeAgo date={comment.created_at} minPeriod={10} component={'span'}
                                                  live={false} formatter={formatter}/></span>
                            <span className={'delete'} onClick={this.onDelete(comment._id)}>
                                {i18n.t("Delete Comment")}
                            </span>
                        </div>}
                    </div>}
                </div>
            </form>
        )
    }
}

export const CommentEditor = withTranslation()(_CommentEditor);

export class Comments extends Component {
    static propTypes = {
        comments: PropTypes.array.isRequired
    };

    render = () => {
        const {comments, handleSubmit, onCommentChange} = this.props;
        return comments.map((c) => {
            return <Comment onCommentChange={onCommentChange} comment={c} handleSubmit={handleSubmit} key={c._id}/>
        })
    }
}

class _Comment extends Component {
    static propTypes = {
        comment: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    render = () => {
        const {handleSubmit, onCommentChange} = this.props;
        return (
            <div>
                <CommentEditor onCommentChange={onCommentChange} comment={this.props.comment}
                               handleSubmit={handleSubmit} isNew={false}/>
            </div>
        )
    }
}


const mapStateToCommentProps = (state, ownProps) => {
    return ({
        comment: ownProps.comment
    });
};
const Comment = connect(mapStateToCommentProps)(_Comment);
