import {omit} from "lodash";

const userFiles = (state = {loading: false, error: null, files: {}}, action) => {
    switch (action.type) {
        case "RETRIEVED_CONTENT":
            return {
                ...state,
                files: action.payload['userFiles'] || []
            };
        case "@no-persist ADDING FILES":
            return {
                ...state,
                loading: true
            };
        case "@no-persist FILE ERROR":
            return {
                ...state,
                error: action.payload
            };
        case "@no-persist FILE NOERROR":
            return {
                ...state,
                error: false
            };
        case "ADDED_FILES":
            const newObject = {};
            action.payload.map((e) => {
                newObject[e.id] = e;
            });
            return {
                ...state,
                files: {...state.files, ...newObject},
                loading: false
            };
        case "REMOVED_FILES": {
            return omit(state, `files.${action.payload}`);
        }
        default:
            return state;
    }
};

export default userFiles;