import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import FaUserSecret from "react-icons/lib/fa/user-secret";
import FaSignOut from "react-icons/lib/fa/sign-out";
import FaSignIn from "react-icons/lib/fa/sign-in";
import kanbanLogo from "../../assets/images/kanban-logo.svg";
import "./Header.scss";
import {signOut} from "../../actions/auth";

class Header extends Component {

    handleClick = () => {
        this.props.signOut()
    };

    render = () => {
        const {auth} = this.props;
        return (
            <header className="tableHead">
                <Link to="/" className="header-title">
                    <img src={'/' + kanbanLogo} alt="React Kanban logo"/>
                    &nbsp;React Kanban
                </Link>
                <div className="header-right-side">
                    {auth.uid ? (
                        <FaUserSecret className="guest-icon"/>
                    ) : (
                        <FaUserSecret className="guest-icon"/>
                    )}
                    {auth.uid ? (
                        <a className="signout-link" onClick={this.handleClick}>
                            <FaSignOut className="signout-icon"/>
                            &nbsp;Sign out
                        </a>
                    ) : (
                        <a className="signout-link" href="/">
                            <FaSignIn className="signout-icon"/>
                            &nbsp;Sign in
                        </a>
                    )}
                </div>
            </header>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
