import React from "react";
import Spinner from 'react-spinkit';
import './SplashScreen.scss'
const SplashScreen = (props) => (
    <div className={'spinnerContainer'}>
    <Spinner name='wandering-cubes' fadeIn={100} color={'#2db9cb'} />
    <p className={'loadingText'}>{props.text}</p>
    </div>
);

export default SplashScreen;