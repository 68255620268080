import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {removeFile} from "../../actions/uploadFiles";
import {denormalize, schema} from "normalizr";
import flat from "flat";
import Highlighter from "react-highlight-words";
import './SearchManager.scss'
import {Link} from "react-router-dom";
import {flat_props} from "../ApplicationManager/utils";


class SearchManager extends Component {


    constructor(props) {
        super(props);
        this.state = {
            search: props.search || "",
            results: [],
            hits: {}
        };
    }

    componentDidMount = () => {
        this.search(this.state.search)
    };

    recreateTree = () => {
        const card = new schema.Entity("cardsById", {}, {idAttribute: "_id"});
        const list = new schema.Entity(
            "listsById",
            {cards: [card]},
            {idAttribute: "_id"}
        );

        const userfiles_e = new schema.Entity(
            "userFiles",
            {},
        );
        const board = new schema.Entity(
            "boardsById",
            {lists: [list], userFiles: [userfiles_e]},
            {idAttribute: "_id"}
        );
        const {cardsById, listsById, boardsById, userFiles} = this.props.state;
        const entities = {cardsById, listsById, boardsById, userFiles: userFiles.files};
        return {...denormalize('default', board, entities)};
    };

    onChangeSearch = (event) => {
        const newQuery = event.target.value.toLowerCase();
        this.search(newQuery);
    };

    search(new_query) {
        const newQuery = new_query.toLowerCase();
        const board = this.recreateTree();
        const flatten = board.lists.reduce((array, l) => {
            const listCards = l.cards;
            return array.concat(...listCards)
        }, []).filter((c) => !c.archived).map((card) => flat(card));
        const hits = {};
        const results = flatten.filter((c) => {
            return Object.keys(c)
                .filter((c) => {
                    return c.indexOf('_id') < 0 && c.indexOf('color') < 0;
                })
                .reduce((boolean, key) => {
                    if (typeof c[key] === 'string' && c[key].toLowerCase().indexOf(newQuery) > -1) {
                        if (!!hits[c._id]) {
                            hits[c._id].push(c[key])
                        } else {
                            hits[c._id] = [c[key]];
                        }
                        return true;
                    }
                    return boolean || false;
                }, false)
        });
        this.setState({
            search: new_query,
            results,
            hits
        })
    }

    onSubmit = event => {
        event.preventDefault();
        this.search(this.state.search);
    }

    buildSpan = (hit, search) => {
        return <Highlighter
            highlightClassName="highlight"
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={hit}
        />

    };

    render = () => {
        const {i18n} = this.props;
        return (
            <div className={'container'}>
                <h1 className={'mt-4 text-center'}>{i18n.t("Search through all applications")}</h1>
                <form onSubmit={this.onSubmit}>
                    <div className="form-group d-flex flex-row mt-3">
                        <input className="form-control flex-grow mr-3" id="search-field"
                               placeholder={i18n.t("Search anything related to your applications")}
                               value={this.state.search} onChange={this.onChangeSearch}/>
                        <button className="btn btn-success my-2 my-sm-0" type="submit">{i18n.t("Search")}</button>
                    </div>
                </form>

                {!!this.state.results.length &&
                <h2 className={'mb-2'}>{!!this.state.search.length ? `${i18n.t("Results for")} "${this.state.search}"` : i18n.t("Results")}</h2>}
                {!this.state.results.length &&
                <h2 className={'mb-2'}>{`${i18n.t("No Results found for")} "${this.state.search}"`}</h2>}

                {this.state.results.map((e, i) => (
                    <Link to={`/application/${e._id}`} key={e._id}>
                        <div className={'search-result'}>
                            <h5>{e.title} @ {e.company}</h5>
                            {!!this.state.search.length && this.state.hits[e._id].map((e, j) => <span
                                className={'btn btn-outline-secondary mr-2 mb-1'}
                                key={`${i}${j}`}>{e.length > 50 ?
                                <span>{this.buildSpan(e.slice(0, 50), this.state.search)}...</span> : this.buildSpan(e, this.state.search)}</span>)}
                        </div>
                    </Link>
                ))}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const {search} = ownProps.match.params;
    return {
        search, state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeFile: (file) => dispatch(removeFile(file))
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SearchManager))