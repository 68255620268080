import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/images/logo.png";
import classNames from "classnames";
import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import './LPBar.scss'

export default function LandingPageTopBar() {

    const {t} = useTranslation();

    return <Navbar collapseOnSelect bg="primary" variant={'dark'} fixed={'top'}>
        <Navbar.Brand href="#/">
            <img src={logo} height="60" alt=""/>
        </Navbar.Brand>
        <ul className={classNames("navbar-nav ml-auto")}>
            <li className={classNames("nav-item")}>
                <Link className="nav-link text-right smaller-mobile" to="/login">{t("I already have an account")}</Link>
            </li>
        </ul>
    </Navbar>
}