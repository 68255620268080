import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export default function LangChooser(props) {

    const lang = {'en': 'English', 'de': 'Deutsch', 'fr': 'Français'};
    const {i18n, t} = useTranslation();

    const [language, setLanguage] = useState(i18n.language);

    const handleSelectChange = event => {
        let newlang = event.target.value;
        setLanguage(newlang);
        i18n.changeLanguage(newlang);
    };

    return (<div className="form-group">
        {!props.noLabel && <label htmlFor="lang">{t('Change language of YourJobApplication.com')}</label>}
        <select className="form-control" name={'lang'} id="lang"
                onChange={handleSelectChange}
                value={i18n.language}>
            {Object.keys(lang).map((e) => (<option key={e} value={e}>{lang[e]}</option>))}
        </select>
    </div>)

}