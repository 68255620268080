import {denormalize, normalize, schema} from "normalizr";
import {registerLocale} from "react-datepicker";
import en from 'date-fns/locale/en-GB'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'


// Return the total number of checkboxes and the number of checked checkboxes inside a given text
export const findCheckboxes = text => {
    const checkboxes = text.match(/\[(\s|x)\]/g) || [];
    const checked = checkboxes.filter(checkbox => checkbox === "[x]").length;
    return {total: checkboxes.length, checked};
};


const card = new schema.Entity("cardsById", {}, {idAttribute: "_id"});
const list = new schema.Entity(
    "listsById",
    {cards: [card]},
    {idAttribute: "_id"}
);
const userfiles = new schema.Entity(
    "userFiles",
    {},
);
const board = new schema.Entity(
    "boardsById",
    {lists: [list], userFiles: [userfiles]},
    {idAttribute: "_id"}
);


// Boards are stored in a tree structure inside mongoDB.
// This function takes the tree shaped boards and returns a flat structure more suitable to a redux store.
export const normalizeBoards = boards => {
    const {entities} = normalize(boards, [board]);
    return entities;
};

export const denormalizeBoard = (boardId) => {
    const entities = {comment, card, list};
    return denormalize(boardId, board, entities);
};

export const boardInjector = (state) => {
    return {board: state.boardsById['default']};
};

export function getAllUndefined(object) {

    function convertPath(arr, key) {
        var path = "";
        for (var i = 1; i < arr.length; i++) {

            path += arr[i] + "->";
        }
        path += key;
        return path;
    }


    var stack = [];
    var saveUndefined = [];

    function getUndefiend(obj, key) {

        var t = typeof obj;
        switch (t) {
            case "object":
                if (t === null) {
                    return false;
                }
                break;
            case "string":
            case "number":
            case "boolean":
            case "null":
                return false;
            default:
                return true;
        }
        stack.push(key);
        for (let k in obj) {
            if (obj.hasOwnProperty(k)) {
                let v = getUndefiend(obj[k], k);
                if (v) {
                    saveUndefined.push(convertPath(stack, k));
                }
            }
        }
        stack.pop();

    }

    getUndefiend({
        "": object
    }, "");
    return saveUndefined;
}


export const registerLocaleOfDatePicker = () => {
    registerLocale("en", en);
    registerLocale("fr", fr);
    registerLocale("de", de);
};


export const findNextMonth = (date, jump = 1) => {
    let currentMonth = date.getMonth();
    let current = null;
    if (currentMonth > (12 - jump)) {
        current = new Date(date.getFullYear() + 1, (currentMonth + jump) % 12, 1);
    } else {
        current = new Date(date.getFullYear(), currentMonth + jump, 1);
    }
    return current;
};