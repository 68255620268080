import flat, {unflatten} from "flat";

export const flat_props = (card) => {
    Object.keys(card).map((k) => {
        let currentValue = card[k];
        if (currentValue && typeof currentValue.toDate === 'function') {
            card[k] = currentValue.toDate()
        } else if (currentValue && typeof currentValue === 'object') {
            Object.keys(currentValue).map((k1) => {
                if (card[k][k1] && typeof card[k][k1].toDate === 'function') {
                    card[k][k1] = card[k][k1].toDate()
                } else if (typeof card[k][k1] === 'object') {
                    Object.keys(card[k][k1]).map((k2) => {
                        if (card[k][k1][k2] && typeof card[k][k1][k2].toDate === 'function') {
                            card[k][k1][k2] = card[k][k1][k2].toDate()
                        }
                    })
                }
            });
        }
    });
    return flat(card, {delimiter: '-', safe: true});
};

export const unflat_props = (card) => {

    return unflatten(card, {delimiter: '-', safe: true});
};