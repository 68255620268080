import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import persistMiddleware from "./middleware/persistMiddleware";
import rootReducer from "./reducers";
import {composeWithDevTools} from "redux-devtools-extension";
import {applyMiddleware, compose, createStore} from "redux";
import {Provider, useSelector} from "react-redux";
import {createFirestoreInstance, reduxFirestore} from 'redux-firestore'
import firebase from './firebase'
import 'firebase/auth';
import thunk from 'redux-thunk';
import history from './browserhistory'
import logger from 'redux-logger'
import {getFirebase, isLoaded, ReactReduxFirebaseProvider} from 'react-redux-firebase'
import {Router, useLocation} from "react-router";
import SplashScreen from "./components/SplashScreen/SplashScreen";
import "firebase/storage";
import "firebase/database";
import {I18nextProvider, useTranslation} from "react-i18next";
import i18n from "i18n"
import favicon from './assets/images/favicon.png'


const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

const middlewares = [
    persistMiddleware,
    thunk.withExtraArgument(getFirebase),
    logger
];

const createStoreWithFirebase = compose(
    reduxFirestore(firebase, rrfConfig), // firebase instance as first argument, rfConfig as optional second
)(createStore);

const store = createStoreWithFirebase(
    rootReducer,
    // normalizeBoards([createWelcomeBoard()]),
    composeWithDevTools(applyMiddleware(...middlewares))
);


function AuthIsLoaded({children}) {
    const {t, i18n} = useTranslation();
    const auth = useSelector(state => state.firebase.auth);
    let splashScreen = <SplashScreen text={t("Loading...")}/>;
    if (!isLoaded(auth)) {
        return splashScreen
    }
    return children
}

function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        setTimeout(() => {
                window.scrollTo(0, 0);
                let elementsByClassNameElement = document.getElementsByClassName('lists');
                if (elementsByClassNameElement.length) {
                    elementsByClassNameElement[0].scrollLeft = 0;
                }
            }, 10
        )
    }, [pathname]);

    return null;
}

const AppContainer = () => {
    return (
        <Provider store={store}>
            <ReactReduxFirebaseProvider
                firebase={firebase}
                config={rrfConfig}
                dispatch={store.dispatch}
                createFirestoreInstance={createFirestoreInstance}>
                <Router history={history}>
                    <I18nextProvider i18n={i18n}>
                        <AuthIsLoaded>
                            <ScrollToTop/>
                            <App/>
                        </AuthIsLoaded>
                    </I18nextProvider>
                </Router>
            </ReactReduxFirebaseProvider>
        </Provider>
    )
};

ReactDOM.render(<AppContainer/>, document.getElementById('app'));
