import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Draggable} from "react-beautiful-dnd";
import classnames from "classnames";
import ListHeader from "./ListHeader";
import Cards from "./Cards";
import "./List.scss";
import lookup from '../../assets/images/glass.png'
import sent from '../../assets/images/envelope.png'
import interview from '../../assets/images/necktie.png'
import contract from '../../assets/images/document.png'
import tada from '../../assets/images/party.png'

const icons = [lookup, sent, interview, contract, tada]

class List extends Component {
  static propTypes = {
    boardId: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    list: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
    onCardClick: PropTypes.func
  };

  render = () => {
    const { list, boardId, index, onCardClick } = this.props;
    return (
      <Draggable
        draggableId={list._id}
        index={index}
        disableInteractiveElementBlocking
        isDragDisabled={true}
      >
        {(provided, snapshot) => (
          <>
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              className="list-wrapper"
            >
              <div
                className={classnames("list", {
                  "list--drag": snapshot.isDragging
                },`color${index}`)}
              >
                <ListHeader
                  dragHandleProps={provided.dragHandleProps}
                  listTitle={list.title}
                  listId={list._id}
                  cards={list.cards}
                  boardId={boardId}
                  icon={icons[index]}
                />
                <div className="cards-wrapper">
                  <Cards listId={list._id} onCardClick={onCardClick} />
                </div>
              </div>
            </div>
            {provided.placeholder}
          </>
        )}
      </Draggable>
    );
  };
}

export default connect()(List);
