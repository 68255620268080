const initialState = {loading: false, error: null, register: false, password: false, success: false};
const user = (state = initialState, action) => {
    switch (action.type) {
        case "RETRIEVE_EMAIL_STATUS":
            return {
                ...state,
                loading: true,
                error: null,
                success: false
            };
        case "UNKNOWN_EMAIL":
            return {
                ...state,
                register: true,
                loading: false,
                password: true,
                error: null
            };
        case "KNOWN_EMAIL":
            return {
                ...state,
                register: false,
                loading: false,
                password: true,
                error: null
            };
        case "RETRIEVE_EMAIL_ERROR":
            return {
                ...state,
                register: false,
                loading: false,
                password: false,
                error: action.payload
            };
        case "SIGN_IN_WITH_PASSWORD":
        case "SIGN_UP_WITH_PASSWORD":
        case "SIGN_IN_WITH_GOOGLE":
        case "RESET_PASSWORD":
            return {
                ...state,
                loading: true,
                error: null
            };
        case "SIGN_IN_WITH_PASSWORD_SUCCESS":
        case "SIGN_UP_WITH_PASSWORD_SUCCESS":
        case "SIGN_IN_WITH_GOOGLE_SUCCESS":
            return {
                ...initialState,
                success: true
            };
        case "SIGN_IN_WITH_PASSWORD_ERROR":
        case "SIGN_UP_WITH_PASSWORD_ERROR":
        case "SIGN_IN_WITH_GOOGLE_ERROR":
        case "RESET_PASSWORD_ERROR":
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case "RESET_PASSWORD_SUCCESS":
            return {
                ...state,
                loading: false,
                error: "password_reset_success"
            };

        default:
            return state;
    }
};

export default user;
