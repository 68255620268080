const boardsById = (state = {}, action) => {
    switch (action.type) {
        case "RETRIEVED_CONTENT":
            setTimeout(() => {
                //Hack to fix scroll
                window.scrollTo(0, 0);
                let elementsByClassName = document.getElementsByClassName('lists');
                if (elementsByClassName.length) {
                    elementsByClassName[0].scrollLeft = 0;
                }
            }, 100);
            return action.payload['boardsById'];
        case "ADD_LIST": {
            const {boardId, listId} = action.payload;
            return {
                ...state,
                [boardId]: {
                    ...state[boardId],
                    lists: [...state[boardId].lists, listId]
                }
            };
        }
        case "MOVE_LIST": {
            const {oldListIndex, newListIndex, boardId} = action.payload;
            const newLists = Array.from(state[boardId].lists);
            const [removedList] = newLists.splice(oldListIndex, 1);
            newLists.splice(newListIndex, 0, removedList);
            return {
                ...state,
                [boardId]: {...state[boardId], lists: newLists}
            };
        }
        case "DELETE_LIST": {
            const {listId: newListId, boardId} = action.payload;
            return {
                ...state,
                [boardId]: {
                    ...state[boardId],
                    lists: state[boardId].lists.filter(listId => listId !== newListId)
                }
            };
        }
        case "ADD_BOARD": {
            const {boardTitle, boardId, userId} = action.payload;
            return {
                ...state,
                [boardId]: {
                    _id: boardId,
                    title: boardTitle,
                    lists: [],
                    users: [userId],
                    color: "blue"
                }
            };
        }
        case "CHANGE_BOARD_TITLE": {
            const {boardTitle, boardId} = action.payload;
            return {
                ...state,
                [boardId]: {
                    ...state[boardId],
                    title: boardTitle
                }
            };
        }
        case "CHANGE_BOARD_COLOR": {
            const {boardId, color} = action.payload;
            return {
                ...state,
                [boardId]: {
                    ...state[boardId],
                    color
                }
            };
        }
        case "DELETE_BOARD": {
            const {boardId} = action.payload;
            const {[boardId]: deletedBoard, ...restOfBoards} = state;
            return restOfBoards;
        }
        case "ADDED_FILES": {
            return {
                ...state,
                'default': {
                    ...state['default'],
                    userFiles: state['default']['userFiles'].concat(action.payload.map((e) => e.id))
                }

            }
        }
        case "REMOVED_FILES": {
            return {
                ...state,
                'default': {
                    ...state['default'],
                    userFiles: state['default']['userFiles'].filter((e) => e !== action.payload)
                }

            }
        }
        case "USER_CANCELLED_TUTORIAL": {
            return {
                ...state,
                'default': {
                    ...state['default'],
                    skip_tutorial: true
                }
            }
        }
        default:
            return state;
    }
};

export default boardsById;
