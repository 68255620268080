import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Textarea from "react-textarea-autosize";
import {Button, Wrapper, Menu, MenuItem} from "react-aria-menubutton";
import FaTrash from "react-icons/lib/fa/trash";
import "./ListHeader.scss";
import {withTranslation} from "react-i18next";

class ListTitle extends Component {
    static propTypes = {
        listTitle: PropTypes.string.isRequired,
        listId: PropTypes.string.isRequired,
        boardId: PropTypes.string.isRequired,
        cards: PropTypes.arrayOf(PropTypes.string).isRequired,
        dragHandleProps: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired
    };


    render() {
        const {dragHandleProps, listTitle, i18n, icon} = this.props;
        return (
            <div className="list-header">
                <img src={icon}/>
                <div
                    {...dragHandleProps}
                    role="button"
                    tabIndex={0}
                    className="list-title-button">
                    {i18n.t(listTitle)}
                </div>
            </div>
        );
    }
}

export default withTranslation()(connect()(ListTitle));
