import React, {Component} from "react";
import isUrl from 'is-url'
import classNames from "classnames";
import FaGlobe from "react-icons/lib/fa/globe";
import {withTranslation} from "react-i18next";

const types = {
    'web': {
        "value": "I found the job on the Internet",
        "placeholder": 'Enter the URL for this job offer',
        "label": 'URL'
    },
    'recommendation': {
        "value": "I was recommended by someone",
        "placeholder": 'Enter the contact data of the person who recommended you',
        "label": "Contact Person"
    },
    'headhunter': {
        "value": "I have been submitted by a headhunter",
        "placeholder": 'Enter the contact data of the headhunter',
        "label": "Contact Person"
    },
    'other': {"value": "Somehow else", "placeholder": 'Specify any details you want to remember', "label": "Other"}
};


class JobSearch extends Component {


    constructor(props) {
        super(props);
        this.state = {
            "search-type": props.type,
            "search-vector": props.value
        }
    }


    handleSelectChange = (event) => {
        const parentChange = this.props.onChange;
        let newType = event.target.value;
        let oldType = this.state["search-type"];
        let newState = {};
        if (event.target.name === 'search-type' && newType !== oldType) {
            newState = {"search-type": newType, "search-vector": ""};
            this.setState(newState)
        } else {
            newState = {
                ...this.state,
                [event.target.name]: event.target.value
            };
            this.setState(newState)
        }
        parentChange(newState)
    };

    render = () => {
        const {props} = this;
        const {i18n} = props;
        let hasURL = isUrl(props.value);
        return (
            <>
                <h2>{i18n.t("Job Search Info")}</h2>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label htmlFor="search-type">{i18n.t('How did you find the job?')}</label>
                            <select className="form-control" name={'search-type'} id="search-type"
                                    onChange={this.handleSelectChange}
                                    value={props.type}>
                                {Object.keys(types).map((e) => (
                                    <option key={e} value={e}>{i18n.t(types[e].value)}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-8">
                        <div className='row'>
                            <div className={classNames({'col-10': hasURL, 'col-12': !hasURL, 'col-md-11': hasURL})}>
                                <div className="form-group">
                                    <label htmlFor="search-vector">{i18n.t(types[props.type].label)}</label>
                                    <input className="form-control" name="search-vector"
                                           onChange={this.handleSelectChange}
                                           placeholder={i18n.t(types[props.type].placeholder)}
                                           value={props.value}/>
                                </div>
                            </div>
                            {
                                hasURL &&
                                <div className={classNames('col-1', 'mt-4', 'pl-0')}>
                                    <a href={props.value} target={'_blank'}>
                                        <FaGlobe size={'2em'}/>
                                    </a>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


export default withTranslation()(JobSearch)

