import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import './ApplicationFiles.scss'
import {map} from 'lodash';
import {connect} from 'react-redux';
import {uploadFiles} from "../../../actions/uploadFiles";
import shortid from 'shortid'
import classNames from "classnames";
import FaDownload from "react-icons/lib/fa/download";
import {withTranslation} from "react-i18next";


const mimes = ['application/pdf', 'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword', 'image/png', 'image/jpeg'].join(", ");


// Path within Database for metadata (also used for file Storage path)

class Uploader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: props.selected
        };
    }


    onFilesDropAccepted = (files) => {
        const {props} = this;
        const created_ids = [];
        const created_files = {};
        files.forEach((file) => {
            const id_for_file = shortid.generate();
            created_ids.push(id_for_file);
            created_files[id_for_file] = file
        });
        let newState = this.state.selected.concat(created_ids);
        this.setState({
            selected: newState
        }, () => {
            const selected = this.state.selected;
            this.props.onChange({applicationDocuments: selected})
        });
        props.uploadFile(props.auth.uid, created_files)
    };

    onFilesDropRejected = (files) => {
        const {props} = this;
        props.dispatch({type: '@no-persist FILE ERROR', payload: files.map((e) => e.name).join(', ')});
        setTimeout(function () {
            props.dispatch({type: '@no-persist FILE NOERROR'});
        }, 5000)
    };

    isChecked = (file) => {
        return this.state.selected.indexOf(file.id) >= 0;
    };

    onCheckBoxChange = (id) => {
        return () => {
            let selected = this.state.selected;
            const wasSelected = this.state.selected.indexOf(id) >= 0;
            if (!wasSelected) {
                selected = selected.concat([id]);
            } else {
                selected = selected.filter((e) => e !== id);
            }
            this.setState({selected});
            this.props.onChange({applicationDocuments: selected})
        }
    };

    render = () => {
        const {props} = this;
        const i18n = props.i18n;
        return (
            <div>
                <Dropzone onDropAccepted={this.onFilesDropAccepted} onDropRejected={this.onFilesDropRejected}
                          accept={mimes} multiple={true} maxSize={10 * 1024 * 1024}>
                    {({getRootProps, getInputProps}) => (
                        <div className={classNames('dropzone', {'error': !!props.error})} {...getRootProps()}>
                            <input {...getInputProps()} />
                            {!!props.error &&
                            <p className={'text-center'}>{`${i18n.t('Unknown error happened, check')} ${props.error}`}</p>}
                            {!props.loading && !props.error &&
                            <p className={'text-center'}>{i18n.t("Click to add some documents to your application (i.e. CV, Diploma)")}</p>}
                            {!props.loading && <>
                                <p className={'text-center'}>{i18n.t("Supported formats PDF, DOCX, DOC, ODT, PNG, JPG")}</p>
                                <p className={'text-center'}>{i18n.t("Max Size 10MB")}</p>
                            </>}
                            {props.loading && <p>{i18n.t("Uploading Data...")}</p>}
                        </div>
                    )}
                </Dropzone>
                {!!Object.values(props.uploadedFiles).length && (
                    <div>
                        <h4>{i18n.t("Files in your Document Library")}</h4>
                        <div className={'big-container'}>
                            <div className={'row-container'}>
                                <p className={"text-center mt-2"}>{i18n.t("Document present in your file library but not linked to this application")}</p>
                                {map(props.uploadedFiles, (file, key) => {
                                    if (this.isChecked(file)) {
                                        return null;
                                    }
                                    return (
                                        <div key={key} className={"files-container"}>
                                            <div
                                                className={classNames("checkbox-container mr-0 mr-sm-3", {"selected": this.isChecked(file)})}
                                                onClick={this.onCheckBoxChange(file.id)}>
                                                <p className={'file-name'}>{file.name}</p>
                                            </div>
                                            <a className={'d-none d-sm-block'} href={file.url} target={'_blank'}
                                               key={file.name + key}>
                                                <span><FaDownload size={'1.5em'}/></span>
                                            </a>
                                        </div>
                                    )
                                })}</div>
                            <div className={'separator'}>
                                <div></div>
                            </div>
                            <div className={'row-container'}>
                                <p className={"text-center mt-2"}>{i18n.t("Document present in your file library and linked to this application")}</p>
                                {map(props.uploadedFiles, (file, key) => {
                                    if (!this.isChecked(file)) {
                                        return null;
                                    }
                                    return (
                                        <div key={key} className={"files-container"}>
                                            <div
                                                className={classNames("checkbox-container mr-0 mr-sm-3", {"selected": this.isChecked(file)})}
                                                onClick={this.onCheckBoxChange(file.id)}>
                                                <p className={'file-name'}>{file.name}</p>
                                            </div>
                                            <a className={'d-none d-sm-block'} href={file.url} target={'_blank'}
                                               key={file.name + key}>
                                                <span><FaDownload size={'1.5em'}/></span>
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <small>{i18n.t("Click on the file to pass it from one column to the other")}</small>
                    </div>
                )}
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    let auth = state.firebase.auth;
    let uploadedFiles = state.userFiles.files;
    let loading = state.userFiles.loading;
    let error = state.userFiles.error;
    return {
        auth, uploadedFiles, loading, error, ...ownProps
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFile: (uid, files) => dispatch(uploadFiles(uid, files)),
        dispatch
    }
};


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Uploader));



