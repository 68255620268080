import React, {Component} from "react";
import {connect} from "react-redux";
import './SettingsManager.scss'
import {removeFile} from "../../actions/uploadFiles";
import {withTranslation} from "react-i18next";
import {deleteAndSignOut, signOut} from "../../actions/auth";
import LangChooser from "../LangChooser/LangChooser";
import {Link} from "react-router-dom";
import TimeAgo from "react-timeago";
import {timeagoFormatter} from "../../i18n";


class SettingsManager extends Component {

    deleteDocument = (file) => {
        return () => {
            this.props.removeFile(file);
        }
    };

    signOut = () => {
        if (this.props.auth.isAnonymous) {
            if (window.confirm(this.props.i18n.t('Are you sure you want to sign out? Because you created no account, if you sign out, you will loose all your data.'))) {
                this.props.signOut();
            }
        } else {
            this.props.signOut();
        }
    };

    deleteAndSignOut = () => {
        if (window.confirm(this.props.i18n.t('Are you sure you want to delete your account? That cannot be undone!'))) {
            this.props.deleteAndSignOut();
        }
    };

    restore = (id) => {
        return (event) => {
            event.preventDefault();
            this.props.dispatch({
                type: "CHANGE_CARD",
                payload: {
                    ...this.props.cardsById[id],
                    archived: false
                }
            });
        }
    };

    deleteDefinitely = (id) => {

        return (event) => {
            event.preventDefault();
            if (window.confirm(this.props.i18n.t('Are you sure you want to delete this application, this cannot be undone!'))) {
                this.props.dispatch({
                    type: "DELETE_CARD_DEFINITELY",
                    payload: id
                });
            }
        }
    };

    render = () => {
        const {props} = this;
        const {i18n} = props;
        let currentLang = i18n.language;
        const formatter = timeagoFormatter(currentLang);
        return (
            <div className="container mb-5">

                <h1 className={'mt-4 text-center'}>{i18n.t("Settings")}</h1>
                {!!Object.values(props.files).length &&
                <>
                    <h2>{i18n.t("Document Library")}</h2>
                    {Object.values(props.files).filter((e) => !!e.ref).map((e, i) => (
                        <div key={i} className={'delete-container'}>
                            <p className={'mb-0 mr-auto'}>{e.name}</p>
                            <div className={'btn btn-danger'} onClick={this.deleteDocument(e)}>
                                {i18n.t("Delete definitely this document")}
                            </div>
                            <a href={e.url} target={'_blank'}>
                                <div className={'btn btn-primary'}>
                                    {i18n.t("Download this document")}
                                </div>
                            </a>
                        </div>
                    ))}
                </>}
                {!!props.removedCards.length && <>
                    <h2>{i18n.t("Deleted Applications")}</h2>
                    <div className={'mt-4 mb-4'}>
                        {props.removedCards.sort((a, b) => {
                            if (a.created_at.seconds > b.created_at.seconds) {
                                return -1;
                            }
                            if (b.created_at.seconds > a.created_at.seconds) {
                                return 1;
                            }
                            return 0;
                        }).map((e) =>
                            <Link to={`/application/${e._id}`} key={e._id} className={'deleted-application'}>
                                <div className={'deleted-application--container flex-column flex-lg-row'}>
                                    <div className={'text-lg-left text-center'}>
                                        <h5>{e.title} @ {e.company}</h5>
                                        <p className={'grey'}> {i18n.t("Added")} <TimeAgo
                                            date={new Date(e.created_at.seconds * 1000)}
                                            minPeriod={10}
                                            component={'span'}
                                            live={false} formatter={formatter}/></p>
                                        <span
                                            className={'d-none d-md-block btn btn-outline-secondary mr-2 mb-1'}>{i18n.t(e.list.title)}</span>
                                    </div>
                                    <div className={'deleted-application--buttons'}>
                                        <div className={'btn btn-success mr-3'}
                                             onClick={this.restore(e._id)}>{i18n.t("Restore Application")}</div>
                                        <div onClick={this.deleteDefinitely(e._id)}
                                             className={'btn btn-danger'}>{i18n.t("Delete Definitely Application")}</div>
                                    </div>
                                </div>
                            </Link>)}
                    </div>
                </>}


                <h2>{i18n.t("Language")}</h2>
                <LangChooser/>
                <h2>{i18n.t("Account settings")}</h2>
                {!this.props.auth.isAnonymous &&
                <div className={'my-1 btn btn-danger w-100'} onClick={this.signOut}>{i18n.t("Sign out")}</div>}
                <div className={'my-1 btn btn-danger w-100'}
                     onClick={this.deleteAndSignOut}>{i18n.t("Sign out and delete account")}</div>
            </div>
        )
    }

}

const mapStateToProps = (state, ownProps) => {
    let {auth} = state.firebase;
    let {files, loading, error} = state.userFiles;
    let cardsById = state.cardsById;
    let removedCards = Object.values(state.cardsById).filter((c) => c.archived).map((c) => {
        const activeList = Object.values(state.listsById).filter((l) => l.cards.indexOf(c._id) > -1)[0];
        return {...c, list: activeList}
    });
    let i18n = state.i18n;
    return {
        auth, files, loading, error, i18n, removedCards, cardsById, ...ownProps
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeFile: (file) => dispatch(removeFile(file)),
        signOut: () => dispatch(signOut()),
        deleteAndSignOut: () => dispatch(deleteAndSignOut()),
        dispatch
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SettingsManager))