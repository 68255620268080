import React, {Component} from "react";
import logo from '../../assets/images/logo.png'
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import classNames from "classnames";

import './NavHeader.scss'
import FaUserSecret from "react-icons/lib/fa/user-secret";
import FaSignOut from "react-icons/lib/fa/sign-out";
import FaSignIn from "react-icons/lib/fa/sign-in";
import FaPlus from "react-icons/lib/fa/plus";
import {signOut} from "../../actions/auth";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {withTranslation} from "react-i18next";
import history from '../../browserhistory'


class NavHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: ""
        }
    }

    isRouteActive = (route) => {
        return route === this.props.activeRoute;
    };

    handleSignOut = () => {
        this.props.signOut();
    };

    changeSearch = (event) => {
        this.setState({search: event.target.value})
    };

    search = (event) => {
        event.preventDefault();
        this.setState({search: ""});
        history.push(`/search/${this.state.search}`)
    };


    render = () => {
        const {auth, i18n} = this.props;
        return (
            <Navbar collapseOnSelect bg="primary" variant={'dark'} expand="xl" fixed={'top'}>
                <Navbar.Brand href="#/board">
                    <img src={logo} height="60" alt=""/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <div className={"d-none d-xl-flex flex-xl-grow-1"}>
                        <ul className={classNames("navbar-nav", "mr-5")}>
                            <li className={classNames("mr-2", "nav-item", {"active": this.isRouteActive('/board')})}>
                                <Link className="nav-link" to="/board">{i18n.t("Applications Overview")}</Link>
                            </li>
                            <li className={classNames("nav-item", {"active": this.isRouteActive('/settings')})}>
                                <Link className="nav-link" to="/settings">{i18n.t("Settings")}</Link>
                            </li>
                        </ul>
                        <form className="form-inline mt-2 mt-xl-0 flex-grow-1 mr-3" onSubmit={this.search}>
                            <input className="form-control mr-sm-2 flex-grow-1" type="text"
                                   placeholder={i18n.t("Search anything related to your applications")}
                                   aria-label={i18n.t("Search")} name={'search'} value={this.state.search}
                                   onChange={this.changeSearch}/>
                            <button className="btn btn-success my-2 my-sm-0" type="submit">{i18n.t("Search")}</button>
                        </form>
                        <Link className={'d-none d-xl-flex'} to={'/application/new'}>
                            <div className={"mr-3 btn btn-light"}>
                                <FaPlus className="mr-1"/>
                                {i18n.t("Add an application")}
                            </div>
                        </Link>
                        <div className="header-right-side">
                            {auth.isAnonymous && (
                                <FaUserSecret size={'1.5em'} className="guest-icon"/>
                            )}
                            {auth.isAnonymous && <Link to={'/login'}>
                                <div className={"ml-3 btn btn-success"}>
                                    <FaSignIn className="signout-icon"/>
                                    {i18n.t("Sign in")}
                                </div>
                            </Link>}
                            {!auth.isAnonymous && <div className={"ml-3 btn btn-danger"} onClick={this.handleSignOut}>
                                <FaSignOut className="signout-icon"/>
                                {i18n.t("Sign out")}
                            </div>}
                        </div>
                    </div>
                    <div className={"d-flex-grow-1 d-xl-none"}>
                        <ul className={classNames("navbar-nav mobile")}>
                            <li className={classNames("nav-item text-center", {"active": this.isRouteActive('/board')})}>
                                <Nav.Link className="nav-link"
                                          href="#/board">{i18n.t("Applications Overview")}</Nav.Link>
                            </li>
                            <li className={classNames("nav-item text-center", {"active": this.isRouteActive('/application/new')})}>
                                <Nav.Link className="nav-link"
                                          href="#/application/new">{i18n.t("Add an application")}</Nav.Link>
                            </li>
                            <li className={classNames("nav-item text-center", {"active": this.isRouteActive('/search')})}>
                                <Nav.Link className="nav-link" href="#/search">{i18n.t("Search")}</Nav.Link>
                            </li>
                            <li className={classNames("nav-item text-center", {"active": this.isRouteActive('/settings')})}>
                                <Nav.Link className="nav-link" href="#/settings">{i18n.t("Settings")}</Nav.Link>
                            </li>
                            {auth.isAnonymous && <li className={classNames("nav-item text-center")}>
                                <Nav.Link className="nav-link" href="#/login">{i18n.t("Sign in")}</Nav.Link>
                            </li>}
                            {!auth.isAnonymous && <li className={classNames("nav-item text-center")}>
                                <Nav.Link className="nav-link"
                                          onClick={this.handleSignOut}>{i18n.t("Sign out")}</Nav.Link>
                            </li>}
                        </ul>
                    </div>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        activeRoute: ownProps.location.pathname,
        auth: state.firebase.auth,
        ...ownProps
    };

};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(NavHeader)));
