import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import "./LandingPage.scss";
import {Redirect} from "react-router";
import {signInAnonymous} from "../../actions/auth";
import {Link} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/images/logo.png";
import classNames from "classnames";
import {withTranslation} from "react-i18next";
import withyja from '../../assets/images/with_yja.png'
import allinone from '../../assets/images/allinone.png'
import organise from '../../assets/images/organise.png'
import secure from '../../assets/images/secure.png'


class LandingPage extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired
    };

    state = {redirect: null};

    enterAsGuest = () => {
        // this.props.dispatch({type: "ENTER_AS_GUEST"});
        this.props.signIn();
    };

    render = () => {
        const {redirect} = this.state;
        const {uid} = this.props.auth;
        const {i18n} = this.props;
        if (redirect !== null) {
            return (<Redirect to={redirect}/>)
        }
        if (uid) {
            return (<Redirect to={'/board'}/>)
        }
        return (
            <>
                <header className="masthead">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-12 text-center">
                                <h1>{i18n.t('Need a hand with your job search?')}</h1>
                                <div onClick={this.enterAsGuest}
                                     className={'btn btn-primary w-75 w-xl-50 mt-4'}>{i18n.t('Start using yourjobapplication')}</div>
                            </div>
                        </div>
                    </div>
                </header>

                <section className="py-5">
                    <div className="container">
                        <h2 className="font-weight-light text-center">{i18n.t('With YourJobApplication, keeping track of your applications has never been that easy!')}</h2>
                        <div className={'row'}>
                            <div className={'col-10 offset-1 py-3'}>
                                <img src={withyja} className={'img-fluid'}/>
                            </div>
                        </div>
                        <hr className={'my-5'}/>
                        <h2 className="font-weight-light text-center mt-4">{i18n.t('Why do need to use yourjobapplication.com?')}</h2>
                        <div className={'row usp'}>
                            <div className={'col-12 col-md-4 col-sm-12 d-flex flex-column'}>
                                <div className="card h-100">
                                    <img className="card-img-top" src={allinone}
                                         alt="Card image cap"/>
                                    <div className="card-body">
                                        <h5 className="card-title">{i18n.t('All in one place')}</h5>
                                        <ul className="card-text">
                                            <li>{i18n.t('Centralize all your applications in one place.')}</li>
                                            <li>{i18n.t('Save the whole history of your job applications.')}</li>
                                            <li>{i18n.t('Never loose an application document again!')}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-12 col-md-4 col-sm-12 d-flex flex-column'}>
                                <div className="card h-100">
                                    <img className="card-img-top" src={organise}
                                         alt="Card image cap"/>
                                    <div className="card-body">
                                        <h5 className="card-title">{i18n.t('Organise')}</h5>
                                        <ul className="card-text">
                                            <li>{i18n.t('Be always aware of the state of every application.')}</li>
                                            <li>{i18n.t('Enter every detail about the job offer that you want to remember.')}</li>
                                            <li>{i18n.t('Search through all your applications faster than ever!')}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-12 col-md-4 col-sm-12 d-flex flex-column'}>
                                <div className="card h-100">
                                    <img className="card-img-top" src={secure}
                                         alt="Card image cap"/>
                                    <div className="card-body">
                                        <h5 className="card-title">{i18n.t('Secure and Private')}</h5>
                                        <ul className="card-text">
                                            <li>{i18n.t('Your data are only accessible by you.')}</li>
                                            <li>{i18n.t('We don\'t sell your data.')}</li>
                                            <li>{i18n.t('You will never receive any newsletter or whatsover from our side.')}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'btn btn-primary w-100'}
                             onClick={this.enterAsGuest}>{i18n.t('Start using yourjobapplication')}</div>
                    </div>
                </section>
            </>
        )
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: () => dispatch(signInAnonymous())
    }
};

const mapStateToProps = (state, props) => {
    return {
        auth: state.firebase.auth,
        ...props
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LandingPage));
