import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";

const StringField = (props) => (
    <div className="form-group">
        <label htmlFor={props.binding}>{props.label}</label>
        <input className="form-control" name={props.binding} id={props.binding}
               onChange={props.onChange}
               placeholder={props.placeholder}
               spellCheck="true"
               value={props.value[props.binding] || ''}/>
    </div>
);


StringField.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    binding: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired
};

export default StringField;