import {useTranslation} from "react-i18next";
import React, {Component} from "react";
import waiveHand from '../../assets/images/waving-hand.png'
import {Link} from "react-router-dom";

import './WelcomeModal.scss'

export default function WelcomeModal(props) {

    const {i18n} = useTranslation();

    const conditionalClose = (event) => {
        try {
            const value = event.target.getAttribute('data-close');
            if (value === 'close') {
                props.onClose()
            }
        } catch (e) {}
    };

    return (
        <div className="simple-modal" tabIndex="0" onClick={conditionalClose} data-close={'close'}>
            <div className="simple-modal__content">
                <header className={'simple-modal__header'}>
                    <h4>{i18n.t('Hello!')}</h4>
                    <span data-close={'close'}>X</span>
                </header>
                <div className="modal-main">
                    <img src={waiveHand}/>
                    <div>
                        <p>{i18n.t('Welcome to yourjobapplication.com, your tool to better organise your applications.')}</p>
                        <p>{i18n.t('This is how it works')}</p>
                        <ol>
                            <li>{i18n.t('Add an application long')}<br/>
                                {i18n.t('To track an new application, you need the company name and the job title, but you can add a lot more information to it as well.')}
                            </li>
                            <li>{i18n.t('Drag and drop the card to move it between the different application states')}</li>
                            <li>{i18n.t('Click on a card to change and add information about your application.')}</li>
                            <li>{i18n.t('Via the search bar you can easily (re)find the application you need')}</li>
                            <li>{i18n.t('What do you think? It sounds easy, doesn\'t it? Let\'s create your first application!')}</li>
                        </ol>
                    </div>
                </div>
                <footer className={'simple-modal__footer'}>
                    <Link to={'/application/new'}>
                        <div className={'btn btn-large btn-success'}>{i18n.t('Create an application')}</div>
                    </Link>
                </footer>
            </div>
        </div>
    )
}