import firebase from 'firebase/app'
import 'firebase/firestore'

const config = {
    apiKey: "AIzaSyBlYov8Wy3Wn7OPQTNX1bJh4CsXB2mqSaQ",
    authDomain: "yourjobapplication-d8810.firebaseapp.com",
    databaseURL: "https://yourjobapplication-d8810.firebaseio.com",
    projectId: "yourjobapplication-d8810",
    storageBucket: "yourjobapplication-d8810.appspot.com",
    messagingSenderId: "718866563295",
    appId: "1:718866563295:web:965d84365c14f6b1d2a96a",
    measurementId: "G-G99JL8SRKQ"
};


firebase.initializeApp(config);
firebase.firestore();
export default firebase
