const cardsById = (state = {}, action) => {
    switch (action.type) {
        case "RETRIEVED_CONTENT":
            return action.payload['cardsById'] || {};
        case "ADD_CARD": {
            const {card} = action.payload;
            return {...state, [card._id]: card};
        }
        case "CHANGE_CARD": {
            const {_id} = action.payload;
            return {...state, [_id]: {...state[_id], ...action.payload}};
        }
        case "DELETE_CARD": {
            const {_id} = action.payload;
            return {...state, [_id]: {...state[_id], archived: true}};
        }
        case "CHANGE_CARD_DATE": {
            const {date, cardId} = action.payload;
            return {...state, [cardId]: {...state[cardId], date}};
        }
        case "CHANGE_CARD_COLOR": {
            const {color, cardId} = action.payload;
            return {...state, [cardId]: {...state[cardId], color}};
        }
        case "DELETE_CARD_DEFINITELY": {
            const cardId = action.payload;
            const {[cardId]: deletedCard, ...restOfCards} = state;
            return restOfCards;
        }
        // Find every card from the deleted list and remove it (actually unnecessary since they will be removed from db on next write anyway)
        case "DELETE_LIST": {
            const {cards: cardIds} = action.payload;
            return Object.keys(state)
                .filter(cardId => !cardIds.includes(cardId))
                .reduce(
                    (newState, cardId) => ({...newState, [cardId]: state[cardId]}),
                    {}
                );
        }
        case "ADD_COMMENT": {
            const cardId = action.payload.cardId;
            const {_id} = action.payload.comment;
            let newVar = {...state, [cardId]: {...state[cardId], comments: [_id, ...state[cardId].comments]}};
            return newVar;
        }
        case "REMOVED_FILES": {
            // Remove the given file from applicationFiles
            let newState = state;
            Object.keys(state).map((cardid) => {
                newState = {
                    ...newState,
                    [cardid]: {
                        ...newState[cardid],
                        applicationDocuments: newState[cardid]['applicationDocuments'].filter((e) => e !== action.payload)
                    }
                }
            });
            return newState;
        }
        default:
            return state;
    }
};

export default cardsById;
