import shortid from "shortid";
import {COLUMN_ID_1, COLUMN_ID_2, COLUMN_ID_3, COLUMN_ID_4, COLUMN_ID_5} from "./constants";


export const createEmptyCard = () => ({
    "color": "white",
    "_id": shortid.generate(),
    "title": "",
    "company": "",
    "contact_person": {
        "name": "",
        "email": "",
        "phone": "",
        "comments": []
    },
    "description": "",
    "location": "",
    "search": {
        "type": "web",
        "vector": ""
    },
    "created_at": new Date(),
    "applied_on": null,
    "archived": false,
    comments: [],
    attributes: {
        timeframe: {
            start: null,
            end: null,
            probation: null,
            notice: null,
            comments: []
        },
        salary: {
            amount: null,
            unit: null,
            comments: []
        },
        contract: {
            type: null,
            comments: []
        },
        perks: {
            elements: [],
            comments: []
        },
        holidays: {
            days: null,
            comments: []
        }
    },
    applicationDocuments: []
});


// Generate the initial showcase board that every user and guest gets when they first log in
const createWelcomeBoard = userId => {

    return {
        _id: 'default',
        title: "Applications overview",
        color: "blue",
        lists: [
            {
                _id: COLUMN_ID_1,
                title: "Pending applications",
                cards: []
            },
            {
                _id: COLUMN_ID_2,
                title: "Sent Applications",
                cards: []
            },
            {
                _id: COLUMN_ID_3,
                title: "Selection Process",
                cards: []
            },
            {
                _id: COLUMN_ID_4,
                title: "Offer Negotiation",
                cards: []
            },
            {
                _id: COLUMN_ID_5,
                title: "Accepted job",
                cards: []
            }
        ],
        userFiles : []
    };
};

export default createWelcomeBoard;
