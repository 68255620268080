import React, {Component} from "react";
import {connect} from "react-redux";
import {Prompt, Redirect} from "react-router";
import {CommentEditor, Comments} from "./Comment/Comment";
import {Link} from "react-router-dom";
import JobSearch from "./JobSearch/JobSearchComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './ApplicationManager.scss'
import {flat_props, unflat_props} from "./utils";
import JobSpecs from "./JobSpecs/JobSpecs";
import ReactEditableList from './EditableList/EditableList';
import StringField from "./StringField/StringField";
import JobSalaryComponent from "./JobSalary/JobSalaryComponent";
import {createEmptyCard} from "../../createWelcomeBoard";
import {COLUMN_ID_1} from "../../constants";
import ApplicationFiles from "./ApplicationFiles/ApplicationFiles";
import {withTranslation} from "react-i18next";
import ApplicationMover from "./ApplicationMover/ApplicationMover";
import ReactDOM from 'react-dom';
import classNames from "classnames";
import moment from "moment";

class ApplicationManager extends Component {

    constructor(props) {
        super(props);
        let {card} = this.props;
        card = flat_props(card);
        this.state = {
            card,
            redirect: null,
            dirty: false,
            height: 0,
            mode: 'fixed'
        }
    }

    componentDidMount = () => {
        this.setHeight();
        document.addEventListener('scroll', this.onScroll);
        window.addEventListener('resize', this.setHeight);
    };

    setHeight = () => {
        let rect = ReactDOM.findDOMNode(this.end).getBoundingClientRect();
        const height = rect.y + rect.height + 30;
        console.log("Trigger Height", height);
        this.setState({height});
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setHeight);
        document.removeEventListener('scroll', this.onScroll);
    };

    onScroll = () => {
        const currentScroll = window.scrollY + window.innerHeight;
        const currentState = currentScroll > this.state.height ? 'relative' : 'fixed';
        if (currentState !== this.state.mode) {
            console.log('Should change');
            this.setState({mode: currentState})
        }
    };


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.card.archived) {
            // Restore card calls send form
            this.restoreCard()
        } else {
            this.sendForm();
        }
    };

    sendForm = () => {
        let newVersion = unflat_props(this.state.card);
        if (!this.props.isNew) {
            this.props.dispatch({
                type: "CHANGE_CARD",
                payload: {
                    ...newVersion,
                }
            });
        } else {
            this.props.dispatch({
                type: "ADD_CARD",
                payload: {
                    card: newVersion, listId: COLUMN_ID_1
                }
            });
        }
        this.setState({redirect: '/board'})
    };

    handleCommentSubmit = (event, action, comment) => {
        event.preventDefault();
        let newComments = [];
        let comments = this.state.card.comments;
        if (action === 'CHANGE_COMMENT') {
            const {_id} = comment;
            const index = _.findIndex(comments, (o) => o._id === _id);
            newComments = [...comments.slice(0, index), comment, ...comments.slice(index + 1)]
        } else if (action === 'ADD_COMMENT') {
            newComments = comments.concat([comment])
        } else if (action === 'DELETE_COMMENT') {
            newComments = comments.filter((c) => c._id !== comment)
        }
        let newState = {
            ...this.state,
            dirty: true,
            card: {
                ...this.state.card,
                comments: newComments,
            }
        };
        this.setState(newState);
    };

    handleChange = (event) => {
        this.setState({
            card: {
                ...this.state.card,
                [event.target.name]: event.target.value
            },
            dirty: true
        })
    };

    handleSyntheticChange = (newValues) => {
        this.setState({
            card: {
                ...this.state.card,
                ...newValues
            },
            dirty: true
        })
    };

    handleValueChange = (name) => (newValue) => {
        this.setState({
            card: {
                ...this.state.card,
                [name]: newValue
            },
            dirty: true
        })
    };

    deleteCard = () => {
        this.changeArchived(true)
    };

    restoreCard = () => {
        this.changeArchived(false)
    };

    changeArchived = archived => {
        this.setState({
            card: {
                ...this.state.card,
                archived
            },
        }, () => {
            this.sendForm();
        });
    };

    onCommentChange = () => {
        this.setState({
            dirty: true
        });
    };


    render = () => {
        const {isNew, i18n} = this.props;
        const {redirect, card} = this.state;
        const boxStyle = {'bottom': 0, 'left': 0, 'position': this.state.mode, 'width': '100%'};
        if (redirect !== null) {
            return (<Redirect to={redirect}/>)
        }
        return (
            <>
                <Prompt
                    when={!!this.state.dirty}
                    message={i18n.t(`Are you sure you want to leave? You just changed some data and didn't save them`)}
                />
                <div className="container">
                    <h1 className={'mt-4 text-center'}>{isNew ? i18n.t('Create a new job application') : i18n.t('Edit job application')}</h1>
                    {isNew &&
                    <p className={'mt-4'}>{i18n.t("To create a new application, you just need to fill the job title and the company. The other fields are only there to give a better overview of your applications.")}</p>}
                    <form onSubmit={this.handleSubmit}>
                        <h2 className={'mt-4'}>{i18n.t("Job Infos")}</h2>
                        <div className="form-group">
                            <label htmlFor="job-title">{i18n.t("Job Title *")}</label>
                            <input className="form-control" name="title" id="job-title" onChange={this.handleChange}
                                   placeholder={i18n.t("Enter Job Title")}
                                   spellCheck="true"
                                   required={true} value={card.title}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="job-company">{i18n.t("Company *")}</label>
                            <input className="form-control" name="company" id="job-company" onChange={this.handleChange}
                                   placeholder={i18n.t("Enter Company")}
                                   value={card.company} required={true}/>
                        </div>
                        <StringField binding='location' value={card} onChange={this.handleChange}
                                     label={i18n.t("Job Location")} placeholder={i18n.t("Enter Job Location")}/>

                        <div className="form-group">
                            <label htmlFor="job-description">{i18n.t('Job Description')}</label>
                            <textarea className="form-control" name="description" id="job-description"
                                      onChange={this.handleChange}
                                      spellCheck="true"
                                      placeholder={i18n.t("Copy - Paste the job Description, if you want to save it")}
                                      value={card.description}/>
                        </div>

                        <hr/>

                        <JobSearch onChange={this.handleSyntheticChange} type={card['search-type']}
                                   value={card['search-vector']}/>

                        <hr/>
                        <h2>{i18n.t('Contact Person')}</h2>
                        <div className="row">
                            <div className="col-12 col-md">
                                <StringField binding='contact_person-name' value={card} onChange={this.handleChange}
                                             label={i18n.t("Contact Person Name")}
                                             placeholder={i18n.t("Enter Contact Person Name")}/>
                            </div>
                            <div className="col-12 col-md">
                                <StringField binding='contact_person-email' value={card} onChange={this.handleChange}
                                             label={i18n.t("Contact Person Email")}
                                             placeholder={i18n.t("Enter Contact Person Email")}/>
                            </div>
                            <div className="col-12 col-md">
                                <StringField binding='contact_person-phone' value={card} onChange={this.handleChange}
                                             label={i18n.t("Contact Person Phone Number")}
                                             placeholder={i18n.t("Enter Contact Person Phone Number")}/>
                            </div>
                        </div>
                        <hr/>
                        <h2>{i18n.t("Application files")}</h2>
                        <p>{i18n.t("Which document did you send to the recruiters?")}</p>
                        <ApplicationFiles onChange={this.handleSyntheticChange} selected={card.applicationDocuments}/>
                        <hr/>
                        <h2>{i18n.t("Application Process")}</h2>
                        <div className="form-group">
                            <label htmlFor="applicationDate">{i18n.t("Applied on")}</label>
                            <DatePicker
                                dateFormat={i18n.t("yyyy/MM/dd")}
                                locale={i18n.language}
                                className="form-control"
                                selected={card.applied_on}
                                autoComplete={"off"}
                                onChange={this.handleValueChange('applied_on')}
                                id={'applicationDate'}
                                placeholderText={moment().format(i18n.t('momentjs'))}
                            />
                            {!isNew && <ApplicationMover id={card._id}/>}
                        </div>
                        <hr/>
                        <h2>{i18n.t("Job Specs")}</h2>
                        <h5>{i18n.t("Salary")}</h5>
                        <JobSalaryComponent value={card} onChange={this.handleChange}/>
                        <JobSpecs
                            startDateLabel={'attributes-timeframe-start'}
                            endDateLabel={'attributes-timeframe-end'}
                            startDate={card['attributes-timeframe-start']}
                            endDate={card['attributes-timeframe-end']}
                            onStartDateChanged={this.handleValueChange('attributes-timeframe-start')}
                            onEndDateChanged={this.handleValueChange('attributes-timeframe-end')}
                            card={card}
                            handleChange={this.handleChange}
                        />
                        <div className="row">
                            <div className="col-12 col-md">
                                <StringField label={i18n.t('Probation')} binding={'attributes-timeframe-probation'}
                                             onChange={this.handleChange} placeholder={i18n.t('3 months')}
                                             value={card}/>
                            </div>
                            <div className="col-12 col-md">
                                <StringField label={i18n.t('Period of notice')} binding={'attributes-timeframe-notice'}
                                             onChange={this.handleChange} placeholder={i18n.t('1 month')}
                                             value={card}/>
                            </div>
                        </div>
                        <h5>{i18n.t("Holidays")}</h5>
                        <StringField label={i18n.t('Number of holidays')} binding={'attributes-holidays-days'}
                                     onChange={this.handleChange} placeholder={i18n.t('Number of holidays')}
                                     value={card}/>
                        <h5>{i18n.t("Perks")}</h5>
                        <ReactEditableList ref={(divElement) => {
                            this.end = divElement
                        }} list={card['attributes-perks-elements']}
                                           onListChange={this.handleValueChange('attributes-perks-elements')}
                                           showBullets={false}
                                           placeholder={i18n.t("Add a new perk")}
                                           addText={i18n.t('Add perk')}
                        />
                        <div style={boxStyle} className={classNames({'margin-white': this.state.mode === 'fixed'})}>
                            <div className={classNames({
                                container: this.state.mode === 'fixed',
                            })}>
                                <div className={'row'}>
                                    <div className={'col-12 col-md-6'}>
                                        <Link to={'/board'}>
                                            <div
                                                className="w-100 btn btn-outline-primary my-1">{i18n.t('Back to Board')}</div>
                                        </Link>
                                    </div>
                                    <div className={'col-12 col-md-6'}>
                                        <button type="submit"
                                                className="btn btn-success w-100 my-1">
                                            {this.props.isNew ? i18n.t('Create application') : card.archived ? i18n.t('Restore Application') : i18n.t('Save application')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <hr/>

                    <h2>{i18n.t('Comments')}</h2>
                    <CommentEditor onCommentChange={this.onCommentChange} handleSubmit={this.handleCommentSubmit}
                                   isNew={true}/>
                    <Comments onCommentChange={this.onCommentChange} comments={card.comments}
                              handleSubmit={this.handleCommentSubmit}/>
                    <hr/>
                    {!isNew && !card.archived && <>
                        <div className="w-100 btn btn-danger" onClick={() => {
                            if (window.confirm(i18n.t('Are you sure you wish to delete this application?'))) this.deleteCard()
                        }}>
                            {i18n.t('Delete Card')}
                        </div>
                        <hr/>
                    </>}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const {applicationId} = ownProps.match.params;
    let card = null;
    let isNew = false;
    if (applicationId === 'new') {
        card = createEmptyCard();
        isNew = true;
    } else {
        card = state.cardsById[applicationId];
    }
    return {card, isNew, ...ownProps};
};


export default withTranslation()(connect(mapStateToProps)(ApplicationManager));
