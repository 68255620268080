import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Draggable} from "react-beautiful-dnd";
import classnames from "classnames";
import "./Card.scss";

class Card extends Component {
    static propTypes = {
        card: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            color: PropTypes.string
        }).isRequired,
        listId: PropTypes.string.isRequired,
        isDraggingOver: PropTypes.bool.isRequired,
        index: PropTypes.number.isRequired,
        dispatch: PropTypes.func.isRequired,
        onCardClick: PropTypes.func
    };


    render() {
        const {card, index, isDraggingOver, onCardClick} = this.props;
        if (card.archived){
            return (<></>)
        }
        return (
            <>
                <Draggable draggableId={card._id} index={index}>
                    {(provided, snapshot) => (
                        <>
                            {/* eslint-disable */}
                            <div
                                className={classnames("board-card-title", {
                                    "card-title--drag": snapshot.isDragging
                                })}
                                ref={ref => {
                                    provided.innerRef(ref);
                                    this.ref = ref;
                                }}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={event => {
                                    provided.dragHandleProps.onClick(event);
                                    onCardClick(card._id);
                                }}
                                onKeyDown={event => {
                                    provided.dragHandleProps.onKeyDown(event);
                                    this.handleKeyDown(event);
                                }}
                                style={{
                                    ...provided.draggableProps.style,
                                    background: card.color
                                }}>
                                <div className="card-title-html">
                                    <h6 className={'card-title-header'}>{card.title}</h6>
                                    <p className={'mb-1'}>{card.company}</p>
                                </div>
                                {/* eslint-enable */}
                                {/*{(card.date || checkboxes.total > 0) && (*/}
                                {/*  <CardBadges date={card.date} checkboxes={checkboxes} />*/}
                                {/*)}*/}
                            </div>
                            {/* Remove placeholder when not dragging over to reduce snapping */}
                            {isDraggingOver && provided.placeholder}
                        </>
                    )}
                </Draggable>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    card: state.cardsById[ownProps.cardId]
});

export default connect(mapStateToProps)(Card);
