const currentBoardId = (state = null, action) => {
    switch (action.type) {
        case "PUT_BOARD_ID_IN_REDUX":
        case "RETRIEVED_CONTENT":
        case "ADD_BOARD": {
            return "default"
        }
        default:
            return state;
    }
};

export default currentBoardId;
