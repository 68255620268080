import React, {useState} from 'react'
import {connect, useSelector} from 'react-redux'
import {getLoginMethods, googleSignIn, passwordReset, signIn, signUp} from "../../actions/auth";
import SplashScreen from "../SplashScreen/SplashScreen";
import {useTranslation} from "react-i18next";
import './Login.scss'
import googleLogo from '../../assets/images/google-logo.svg'
import ds from '../../assets/pdf/ds.pdf'
import agb from '../../assets/pdf/agb.pdf'

function LoginPage(props) {
    const auth = useSelector(state => state.firebase.auth);
    const user = useSelector(state => state.user);
    const {t} = useTranslation();
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");

    function loginWithGoogle() {
        return props.signInWithGoogle();
    }

    function submit() {
        if (user.register && user.password) {
            return props.signUp(email, pass)
        } else if (!user.register && user.password) {
            return props.signIn(email, pass)
        } else {
            return props.getLoginMethods(email)
        }
    }

    function onChange(event) {
        setEmail(event.target.value);
    }

    function onChangePass(event) {
        setPass(event.target.value);
    }

    function onPasswordReset() {
        return props.passwordReset(email)
    }

    return (
        <>
            {user.loading && <SplashScreen text={t('Loading...')}/>}
            {!user.loading && <div className={'container'}>
                <div>
                    <h1 className={'text-center mt-4'}>{t('Login/Sign Up')}</h1>

                    {user.error && <div className={'alert alert-danger text-center'}>{t(user.error)}</div>}

                    <div className={'my-4'}>
                        <p>{t("You can use this software without sign up. However, your authentication data are saved on your device. It means that if device gets lost or you clean your browser data, you will loose all your data.")}</p>
                        <p>{t("By using this service, you accept")}
                            <a href={agb} target={'_blank'}>{t('Terms & Conditions')}</a>
                            {t('and the')}
                            <a href={ds} target={'_blank'}>{t('Privacy Policy')}</a>.
                        </p>
                    </div>
                    <form onSubmit={submit}>
                        <div className="row mt-4">
                            <div className={'col-12'}>
                                <div className="form-group">
                                    <label htmlFor={'email'}>{t('E-Mail Address')}</label>
                                    <input className="form-control" name={'email'} id={'email'}
                                           onChange={onChange}
                                           type={'email'}
                                           placeholder={t('Your E-Mail Address')}
                                           value={email}/>
                                </div>
                            </div>
                            <div className={'col-12'}>
                                {user.password && <div className="form-group">
                                    <label htmlFor={'pass'}>{t('Password')}</label>
                                    <input className="form-control" name={'email'} id={'pass'}
                                           onChange={onChangePass}
                                           type={'password'}
                                           placeholder={user.register ? t('Enter a secure password to create your account') : t('Password')}
                                           value={pass}/>
                                </div>}
                            </div>
                        </div>
                        <input type={'submit'} className={'btn btn-primary w-100'}
                               value={user.register ? t('Sign up') : user.password ? t('Sign in') : t('Next')}/>
                        <div className={'row mt-2'}>
                            <div className={'col-12'}>
                                <div>
                                    <small>{t('Forgot password? Enter your email in the field above and click ')}
                                        <span style={{'textDecoration': 'underline', 'cursor': 'pointer'}}
                                              onClick={onPasswordReset}>{t('here')}</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className={'or'}>
                        <div className={'border-gray'}>
                        </div>
                        <div className={'or-content'}>
                            {t('OR')}
                        </div>
                        <div className={'border-gray'}>
                        </div>
                    </div>

                    <button className="btn btn-outline-primary googleBtn" type="button" onClick={loginWithGoogle}>
                        <img
                            src={googleLogo}
                        />
                        {t('Login With Google')}
                    </button>
                </div>
            </div>
            }
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        signInWithGoogle: () => dispatch(googleSignIn()),
        getLoginMethods: (email) => dispatch(getLoginMethods(email)),
        signUp: (email, password) => dispatch(signUp(email, password)),
        signIn: (email, password) => dispatch(signIn(email, password)),
        passwordReset: (email) => dispatch(passwordReset(email))
    }
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)