import React from "react";
import './Feedback.scss'
import waiveHand from '../../assets/images/waving-hand.png'
import {useTranslation} from "react-i18next";

export default function Feedback() {

    const {i18n} = useTranslation();

    const forms = {
        'fr': 'https://docs.google.com/forms/d/e/1FAIpQLSemvKOjuCJ4BYy3FjOPDN31j1A8jE8qtqYRojN6kYqJP93ASQ/viewform?usp=sf_link',
        'en': 'https://docs.google.com/forms/d/e/1FAIpQLSfSVN20YWkDRKTxbIjA5OakLV8C1aiYCQ-xs89tRM5bXSMl_g/viewform?usp=sf_link',
        'de': 'https://docs.google.com/forms/d/e/1FAIpQLScjLqPFx1UdCx_09sM_7r4EqSYmD9abY3_bsKeNggKm3CX_jQ/viewform?usp=sf_link'
    };

    return (<a target={'_blank'} href={forms[i18n.language]}>
            <div className={'d-none d-md-flex feedback'}>
                <img src={waiveHand}/>
                <span>Feedback</span>
            </div>
        </a>
    )
}