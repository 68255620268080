import React from 'react'
import {getLoginMethods, googleSignIn, passwordReset, signIn, signUp} from "../../actions/auth";
import SplashScreen from "../SplashScreen/SplashScreen";
import './Imprint.scss'
import googleLogo from '../../assets/images/google-logo.svg'

function Imprint(props) {

    return (<div className="container">

            <div className="cf mt-4">

                <h1>Impressum</h1>
                <h2>Angaben gemäß § 5 TMG:</h2>
                <p>JobNinja GmbH<br/>
                    Schwanthalerstraße 76
                    80336 München<br/>
                    Registergericht: Amtsgericht München<br/>
                    Registernummer: HRB 228128<br/><br/>
                    Umsatzsteuer-ID (Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz):<br/>
                    DE308162296
                </p>
                <h2>Vertreten durch:</h2>
                <p>Herr Mircea Popa<br/>
                    Herr Laurent Meyer</p>
                <h2>Kontakt:</h2>
                <table>
                    <tbody>
                    <tr>
                        <td>Telefon:</td>
                        <td>017651782303</td>
                    </tr>
                    <tr>
                        <td>E-Mail:</td>
                        <td>laurent@jobninja.com</td>
                    </tr>
                    </tbody>
                </table>
                <br/>

                <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h2>
                <p>
                    Herr Laurent Meyer<br/>
                    JobNinja GmbH<br/>
                    Schwanthalerstr. 76<br/>
                    80336 München
                </p>

                <h2>Hinweis auf EU-Streitschlichtung</h2>
                <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
                    target={'_blank'}
                    href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a><br/> Unsere
                    E-Mail-Adresse
                    finden Sie oben im Impressum.</p>
                <p>Quelle: <em><a target={'_blank'} href="https://www.e-recht24.de/">https://www.e-recht24.de</a></em>
                </p>

                <h2>Haftungsausschluss (Disclaimer)</h2>
                <p><strong>Haftung für Inhalte</strong></p>
                <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                    allgemeinen
                    Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                    verpflichtet,
                    übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen,
                    die
                    auf
                    eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                    Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung
                    ist
                    jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                    Bekanntwerden
                    von
                    entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                <p><strong>Haftung für Links</strong></p>
                <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss
                    haben.
                    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                    verlinkten
                    Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
                    Seiten
                    wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                    waren
                    zum
                    Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
                    Seiten
                    ist
                    jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                    Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
                <p><strong>Urheberrecht</strong></p>
                <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb
                    der
                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
                    Erstellers.
                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                    Soweit
                    die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                    beachtet.
                    Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                    Bekanntwerden
                    von
                    Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>

            </div>

        </div>
    )
}

export default Imprint