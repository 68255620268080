import React, {Component, useState} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

const ApplicationMover = props => {

    const [selected, setSelected] = useState(props.currentListId);

    const onChange = (event) => {
        let newSelected = event.target.value;
        props.dispatch({
            type: "MOVE_CARD",
            payload: {
                sourceListId: props.currentListId,
                destListId: newSelected,
                oldCardIndex: props.indexInList,
                newCardIndex: props.lists[newSelected].cards.length,
                boardId: 'default'
            }
        });
        setSelected(newSelected);
    };

    return (
        <div className={'row mt-2'}>
            <div className="col">
                <div className="form-group">
                    <label htmlFor={'list'}>{props.i18n.t('State of this application')}</label>
                    <select className="form-control" name={'list'} id={'list'}
                            onChange={onChange}
                            value={selected}>
                        {props.listsArray.map((e) => (<option key={e._id} value={e._id}>{props.i18n.t(e.title)}</option>))}
                    </select>
                </div>
            </div>
        </div>
    )
};


const mapStateToProps = (state, ownProps) => {
    const currentApplicationId = ownProps.id;
    const lists = state.listsById;
    const listsArray = Object.values(state.listsById);
    let indexInList = -1;
    const currentListId = listsArray.filter((e) => {
        let _indexInList = e.cards.indexOf(currentApplicationId);
        if (_indexInList >= 0) {
            indexInList = _indexInList;
            return true
        }
        return false
    })[0]._id;
    return {
        listsArray,
        lists,
        currentApplicationId,
        currentListId,
        indexInList,
        ...ownProps
    }
};

export default withTranslation()(connect(mapStateToProps)(ApplicationMover))