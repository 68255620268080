import DatePicker from "react-datepicker";
import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {withTranslation} from "react-i18next";
import StringField from "../StringField/StringField";
import {findNextMonth} from "../../../utils";
import moment from "moment";


class JobSpecs extends Component {

    handleCheckBoxChange = (event) => {
        const active = event.target.checked;
        if (!active) {
            const parentCallback = this.props.onEndDateChanged;
            parentCallback(null);
        }
    };

    // TODO: Add min date on end: minDate={startDate}


    render = () => {
        const {props} = this;
        const {i18n} = props;

        const from = findNextMonth(new Date(), 1);
        const to = findNextMonth(new Date(), 7);

        return (
            <>
                <h5>{i18n.t("Contract Timeframe")}</h5>
                <StringField label={i18n.t('Contract Type')} binding={'attributes-contract-type'}
                             onChange={props.handleChange}
                             placeholder={i18n.t('Fulltime/Part time/Night shifts...')}
                             value={props.card}/>
                <div className={classNames('d-flex', 'flex-column', 'flex-sm-row', 'row')}>
                    <div className={classNames('form-group', 'col')}>
                        <label htmlFor={props.startDateLabel}>{i18n.t('Job starts on')}</label>
                        <DatePicker
                            dateFormat={i18n.t("yyyy/MM/dd")}
                            locale={i18n.language}
                            className="form-control"
                            selected={props.startDate}
                            onChange={props.onStartDateChanged}
                            autoComplete={"off"}
                            id={props.startDateLabel}
                            placeholderText={moment(from).format(i18n.t('momentjs'))}
                        />
                    </div>
                    <div className={classNames('form-group', 'col')}>
                        <div className="form-group">
                            <label htmlFor={props.endDateLabel}>{i18n.t('Job ends on')}</label>
                            <DatePicker
                                dateFormat={i18n.t("yyyy/MM/dd")}
                                locale={i18n.language}
                                className="form-control"
                                selected={props.endDate}
                                autoComplete={"off"}
                                onChange={props.onEndDateChanged}
                                id={props.endDateLabel}
                                placeholderText={moment(to).format(i18n.t('momentjs'))}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

JobSpecs.propTypes = {
    startDateLabel: PropTypes.string.isRequired,
    endDateLabel: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    onStartDateChanged: PropTypes.func.isRequired,
    onEndDateChanged: PropTypes.func.isRequired
};


export default withTranslation()(JobSpecs)