import React, {Component} from "react";
import {Link, Redirect, Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import BoardContainer from "./components/Board/BoardContainer";
import LandingPage from "./components/LandingPage/LandingPage";
import ApplicationManager from "./components/ApplicationManager/ApplicationManager";
import "./App.scss";
import "./BSConfig.scss"
import {loadContent} from "./actions/auth";
import NavHeader from "./components/NavHeader/NavHeader";
import 'bootstrap';
import SplashScreen from "./components/SplashScreen/SplashScreen";
import SettingsManager from "./components/SettingsManager/SettingsManager";
import {useTranslation, withTranslation} from "react-i18next";
import SearchManager from "./components/SearchManager/SearchManager";
import shortid from "shortid";
import LoginPage from "./components/Login/Login";
import Navbar from "react-bootstrap/Navbar";
import logo from "./assets/images/logo.png";
import classNames from "classnames";
import Footer from "./components/Footer/Footer";
import LandingPageTopBar from "./components/LandingPage/LPBar";
import Imprint from "./components/Imprint/Imprint";
import Feedback from "./components/Feedback/Feedback";


class App extends Component {

    componentDidMount = () => {

        const {user, content} = this.props;
        if (content || user.isEmpty) {
            return
        }
        let uid = user.uid;
        this.props.loadContent(uid);
    };


    render = () => {
        const {user, content, i18n} = this.props;
        if (content && user.uid) {
            return (
                <WithNavigation>
                    <Switch>
                        <Route path="/board" component={BoardContainer}/>
                        <Route path="/application/:applicationId"
                               render={(props) => <ApplicationManager {...props} key={props.location.pathname}/>}/>
                        <Route path="/search/:search"
                               render={(props) => <SearchManager {...props} key={props.location.pathname}/>}/>
                        <Route path="/search"
                               render={(props) => <SearchManager {...props} key={props.location.pathname}/>}/>
                        <Route path="/settings" component={SettingsManager}/>
                        <Route path="/login" component={LoginPage}/>
                        <Route path="/imprint" component={Imprint}/>
                        <Redirect to="/board"/>
                    </Switch>
                </WithNavigation>
            );
        }
        if (user.isEmpty) {
            return (
                <WithBootstrap>
                    <Switch>
                        <Route exact path="/" component={LandingPage}/>
                        <Route exact path="/login" component={LoginPage}/>
                        <Route path="/imprint" component={Imprint}/>
                        <Redirect to="/"/>
                    </Switch>
                </WithBootstrap>
            )
        } else {
            return (
                <SplashScreen text={i18n.t("Loading...")}/>
            )
        }

    }
}


function WithBootstrap(props) {
    const {t} = useTranslation();
    return <div className={'bs'}>
        <LandingPageTopBar/>
        <div style={{'paddingTop': '85px', 'height': '100%'}}>

            {props.children}
            <Footer/>
        </div>
    </div>
}

function WithNavigation(props) {
    return <div className={'bs'}>
        <NavHeader/>
        <div style={{'paddingTop': '90px', 'display': 'flex', 'height': 'auto', 'minHeight': '100%'}}>
            {
                /** show as content whatever we provide */
                props.children
            }
        </div>
        <Footer/>
        <Feedback/>
    </div>;
}

const mapStateToProps = state => ({user: state.firebase.auth, content: state.boardsById['default']});

const mapDispatchToProps = (dispatch) => {
    return {
        loadContent: (uid) => dispatch(loadContent(uid))
    }
};

// Use withRouter to prevent strange glitch where other components
// lower down in the component tree wouldn't update from URL changes
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));
