import firebaseInstance from '../firebase'
import {denormalize, schema} from "normalizr";
import userFiles from "../reducers/files";
import {getAllUndefined} from "../utils";


// Persist the board to the database after almost every action.
const persistMiddleware = store => next => action => {
    next(action);
    const {
        user,
        boardsById,
        listsById,
        cardsById,
        userFiles,
        currentBoardId: boardId,
        firebase,
    } = store.getState();

    let uid = firebase.auth.uid;
    if (uid) {
        if (action.type !== 'RETRIEVED_CONTENT' &&
            (typeof action !== 'function') &&
            action.type.indexOf('@@reactReduxFirebase') < 0 &&
            action.type.indexOf('@no-persist') < 0) {
            console.log('Action', action.type);
            // const boardData = denormalizeBoard(boardId);
            const card = new schema.Entity("cardsById", {idAttribute: "_id"});
            const list = new schema.Entity(
                "listsById",
                {cards: [card]},
                {idAttribute: "_id"}
            );

            const userfiles_e = new schema.Entity(
                "userFiles",
                {},
            );
            const board = new schema.Entity(
                "boardsById",
                {lists: [list], userFiles: [userfiles_e]},
                {idAttribute: "_id"}
            );
            const entities = {cardsById, listsById, boardsById, userFiles: userFiles.files};
            const boardData = {...denormalize(boardId, board, entities), updated_at: new Date()};
            console.log(getAllUndefined(boardData));
            return firebaseInstance.firestore().collection('users').doc(uid).set(boardData);
        }
    }


};

export default persistMiddleware;

