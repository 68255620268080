import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Droppable} from "react-beautiful-dnd";
import Card from "../Card/Card";
import {Link} from "react-router-dom";
import './Cards.scss'

class Cards extends Component {
    static propTypes = {
        listId: PropTypes.string.isRequired,
        cards: PropTypes.arrayOf(PropTypes.string).isRequired,
        onCardClick: PropTypes.func
    };

    render() {
        const {listId, cards, onCardClick} = this.props;
        return (
            <Droppable droppableId={listId}>
                {(provided, {isDraggingOver}) => (
                    <>
                        <div className="cards" ref={provided.innerRef}>
                            {cards.map((cardId, index) => (
                                <Link to={`/application/${cardId}`} key={cardId}>
                                    <Card
                                        isDraggingOver={isDraggingOver}
                                        key={cardId}
                                        cardId={cardId}
                                        index={index}
                                        listId={listId}
                                        onCardClick={onCardClick}
                                    />
                                </Link>
                            ))}
                            {provided.placeholder}
                            <div
                                style={{float: "left", clear: "both"}}
                                ref={el => {
                                    this.listEnd = el;
                                }}
                            />
                        </div>
                    </>
                )}
            </Droppable>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    cards: state.listsById[ownProps.listId].cards
});

export default connect(mapStateToProps)(Cards);
