import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import React from "react";
import {registerLocaleOfDatePicker} from "./utils";
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import timeago_fr from 'react-timeago/lib/language-strings/fr'
import timeago_de from 'react-timeago/lib/language-strings/de'
import timeago_en from 'react-timeago/lib/language-strings/en'
import fr from './translations/fr.json'
import de from './translations/de.json'
import en from './translations/en.json'

registerLocaleOfDatePicker();

const translations = {
    en: {
        translations: en
    },
    de: {
        translations: de
    },
    fr: {
        translations: fr
    }
};

const genericDetector = {
    name: 'genericLang',

    lookup(options) {
        let found = [];
        if (typeof navigator !== 'undefined') {
            if (navigator.languages) { // chrome only; not an array, so can't use .push.apply instead of iterating
                for (let i = 0; i < navigator.languages.length; i++) {
                    found.push(navigator.languages[i]);
                }
            }
            if (navigator.userLanguage) {
                found.push(navigator.userLanguage);
            }
            if (navigator.language) {
                found.push(navigator.language);
            }
        }

        return found.length > 0 ? found.map((e) => e.split('-')[0]) : undefined;
    }
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(genericDetector);


i18n.use(languageDetector).init({
    // we init with resources
    resources: translations,
    fallbackLng: "en",
    debug: true,
    whitelist: ['en', 'de', 'fr'],

    detection: {
        order: ['querystring', 'cookie', 'localStorage', 'genericLang', 'navigator', 'htmlTag', 'path', 'subdomain']
    },

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export const timeagoFormatter = (currentLang) => {
    const map = {"fr": timeago_fr, "de": timeago_de, "en": timeago_en};
    return buildFormatter(map[currentLang]);
}

export default i18n;