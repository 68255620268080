import React, {Component} from "react";
import {Redirect} from "react-router";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Board from "./Board";
import Header from "../Header/Header";

import {withTranslation} from "react-i18next";
import WelcomeModal from "../WelcomeModal/WelcomeModal";

class BoardContainer extends Component {


    onClose = () => {
        this.props.dispatch({
            type: "USER_CANCELLED_TUTORIAL",
        });
    };

    render = () => {
        const {props} = this;
        const {board, uid, i18n} = props;
        if (!uid) {
            return (<Redirect to="/"/>)
        }
        if (!board) {
            return (<div><p>Error</p><Header/></div>)
        } else {
            return <>
                {Object.values(props.cards).length < 1 && !props.skip_tutorial &&
                <WelcomeModal onClose={this.onClose}/>}
                <Board board={board}/>
            </>
        }
    }
}


BoardContainer.propTypes = {board: PropTypes.object};

const mapStateToProps = (state, ownProps) => {

    let auth = state.firebase.auth;
    return {
        board: state.boardsById['default'],
        uid: auth.uid,
        cards: state.cardsById,
        skip_tutorial: state.boardsById['default'].skip_tutorial
    };
};


export default withTranslation()(connect(mapStateToProps)(BoardContainer))
