import history from '../browserhistory'
import createWelcomeBoard from "../createWelcomeBoard";
import {normalizeBoards} from "../utils";
import {actionTypes} from "react-redux-firebase";

function setDocument(firebase, userUid, document) {
    return firebase.firestore().collection('users').doc(userUid).set(document);
}

function deleteUser(firebase, user) {
    return Promise.all([
        user.delete().then(() => {
            console.log("Deleted Anonymous User", user.uid)
        }).catch((e) => console.error('Couldn\'t delete user', e.code)),
        firebase.firestore().collection('users').doc(user.uid).delete().then(() => {
            console.log("Deleted Anonymous User Data", user.uid)
        }).catch((e) => console.error('Couldn\'t delete user data', e.code))]);
}

export const signInAnonymous = () => {
    return (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        let board = createWelcomeBoard();
        firebase.auth().signInAnonymously().then((result) => {
            let userUid = result.user.uid;
            return setDocument(firebase, userUid, board);
        }).then(() => {
            dispatch({type: "RETRIEVED_CONTENT", payload: normalizeBoards([board])})
        }).catch((error) => console.log(error))
    }
};

export const signOut = () => {
    return (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        firebase.auth().signOut().then(() => {
            history.push('/');
        }).catch((error) => console.log(error))
    }
};


export const deleteAndSignOut = () => {
    return (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        deleteUser(firebase, firebase.auth().currentUser).then(() => {
            return firebase.auth().signOut();
        }).then(() => {
            history.push('/');
        }).catch((error) => console.log(error))
    }
};

export const loadContent = (userId) => {
    return (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        console.log("Looking for", userId);
        return firebase.firestore().collection('users').doc(userId).get().then((doc) => {
            if (doc.exists) {
                return doc.data();
            } else {
                let board = createWelcomeBoard();
                return setDocument(firebase, userId, board).then(() => board);
            }
        }).then((data) => {
            dispatch({type: "RETRIEVED_CONTENT", payload: normalizeBoards([data])})
        })
    }
};

function alreadyUsedAccount(firebase) {
    return function (error) {
        // Handle Errors here.
        const errorCode = error.code;
        if (errorCode === 'auth/credential-already-in-use') {
            return firebase.auth().signInWithCredential(error.credential);
        } else {
            throw error
        }
    };
}

export const googleSignIn = () => {
    return (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().useDeviceLanguage();
        let anonymousUser = firebase.auth().currentUser;
        const previousUserWasAnonymous = !!anonymousUser && anonymousUser.isAnonymous;
        let signinPromise = null;
        if (previousUserWasAnonymous) {
            signinPromise = firebase.auth().currentUser.linkWithPopup(provider)
                .catch(alreadyUsedAccount(firebase))
                .then((result) => {
                    if (previousUserWasAnonymous && anonymousUser.uid !== result.user.uid) {
                        return deleteUser(firebase, anonymousUser)
                            .then(() => {
                                return result;
                            })
                    }
                    return result;
                }).then((result) => {
                    // Hacky
                    // TODO Create a PR: https://github.com/prescottprue/react-redux-firebase/issues/473
                    dispatch({type: actionTypes.AUTH_LINK_SUCCESS, payload: result.user});
                    return result
                })
        } else {
            signinPromise = firebase.auth().signInWithPopup(provider).catch(alreadyUsedAccount(firebase))
        }
        return signinPromise.then((result) => {
            return dispatch(loadContent(result.user.uid))
        }).then(() => {
            history.push('/');
        }).catch((e) => {
            dispatch({'type': 'SIGN_IN_WITH_GOOGLE_ERROR', payload: e.code})
        });
    }
};

export const getLoginMethods = (email) => {
    return (dispatch, getState, getFirebase) => {
        dispatch({type: 'RETRIEVE_EMAIL_STATUS'});
        const firebase = getFirebase();
        firebase.auth().fetchSignInMethodsForEmail(email).then((signInMethods) => {
                if (signInMethods.length) {
                    if (signInMethods[0] === 'google.com') {
                        const provider = new firebase.auth.GoogleAuthProvider();
                        firebase.auth().useDeviceLanguage();
                        return firebase.auth().signInWithPopup(provider)
                            .then((result) => {
                                return dispatch(loadContent(result.user.uid))
                            })
                            .then(() => {
                                dispatch({type: 'SIGN_IN_WITH_GOOGLE_SUCCESS'})
                            })
                            .catch((e) => {
                                dispatch({type: 'SIGN_IN_WITH_GOOGLE_ERROR', payload: e.code})
                            })
                    } else if (signInMethods[0] === 'password') {
                        dispatch({type: 'KNOWN_EMAIL'})
                    }
                } else {
                    dispatch({type: 'UNKNOWN_EMAIL'})
                }
            }
        ).catch((error) => {
            dispatch({type: 'RETRIEVE_EMAIL_ERROR', payload: error.code})
        });
    }
};

export const signUp = (email, password) => {
    return (dispatch, getState, getFirebase) => {
        dispatch({type: 'SIGN_UP_WITH_PASSWORD'});
        const firebase = getFirebase();
        const startUser = firebase.auth().currentUser;
        const previousUserWasAnonymous = !!startUser && startUser.isAnonymous;
        let newUserId = null;
        let signUpPromise = null;
        const credential = firebase.auth.EmailAuthProvider.credential(email, password);
        if (previousUserWasAnonymous) {
            signUpPromise = startUser.linkWithCredential(credential).then((result) => {
                dispatch({type: actionTypes.AUTH_LINK_SUCCESS, payload: result.user});
                return result
            });
        } else {
            signUpPromise = firebase.auth().createUserWithEmailAndPassword(email, password);
        }
        return signUpPromise.then((res) => {
            newUserId = res.user.uid
        }).then(() => {
            return firebase.auth().currentUser.sendEmailVerification({url: 'https://yourjobapplication.com'})
        }).then(() => {
            return dispatch(loadContent(newUserId))
        }).then(() => {
            dispatch({type: 'SIGN_UP_WITH_PASSWORD_SUCCESS'})
        }).then(() => {
            history.push('/');
        }).catch((e) => {
            dispatch({type: 'SIGN_UP_WITH_PASSWORD_ERROR', payload: e.code})
        })
    }
};

export const signIn = (email, password) => {
    return (dispatch, getState, getFirebase) => {
        dispatch({type: 'SIGN_IN_WITH_PASSWORD'});
        const firebase = getFirebase();
        const startUser = firebase.auth().currentUser;
        const previousUserWasAnonymous = !!startUser && startUser.isAnonymous;
        let newUserId = null;
        firebase.auth().signInWithEmailAndPassword(email, password).then((result) => {
            newUserId = result.user.uid;
            if (previousUserWasAnonymous && startUser.uid !== newUserId) {
                return deleteUser(firebase, startUser);
            }
        }).then(() => {
            return dispatch(loadContent(newUserId))
        }).then(() => {
            dispatch({type: 'SIGN_IN_WITH_PASSWORD_SUCCESS'})
        }).then(() => {
            history.push('/');
        }).catch((e) => {
            dispatch({type: 'SIGN_IN_WITH_PASSWORD_ERROR', payload: e.code})
        })
    }
};


export const passwordReset = (email) => {
    return (dispatch, getState, getFirebase) => {
        dispatch({type: 'RESET_PASSWORD'});
        const firebase = getFirebase();
        firebase.auth().sendPasswordResetEmail(email, {url: 'https://yourjobapplication.com/#/login'}).then(() => {
            dispatch({type: 'RESET_PASSWORD_SUCCESS'})
        }).catch((e) => {
            dispatch({type: 'RESET_PASSWORD_ERROR', payload: e.code})
        })
    }
};
