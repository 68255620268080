import {combineReducers} from "redux";
import cardsById from "./cardsById";
import listsById from "./listsById";
import boardsById from "./boardsById";
import user from "./user";
import isGuest from "./isGuest";
import currentBoardId from "./currentBoardId";
import {firestoreReducer} from "redux-firestore";
import {firebaseReducer} from "react-redux-firebase";
import userFiles from './files'

export default combineReducers({
    cardsById,
    listsById,
    boardsById,
    user,
    isGuest,
    currentBoardId,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    userFiles
});
