import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import './Footer.scss'
import LangChooser from "../LangChooser/LangChooser";
import ds from '../../assets/pdf/ds.pdf'
import agb from '../../assets/pdf/agb.pdf'

function Footer() {

    const {t} = useTranslation();

    return (
        <div className={'footer-container'}>
            <div className={'alert alert-warning text-center'}>{t('This software is in beta version and we encourage you to give us feedback!')}</div>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-12 col-sm-6'}>
                        <strong>yourjobapplication.com</strong>
                        <ul>
                            <li>
                                <Link to={'/imprint'}>{t('Imprint')}</Link>
                            </li>
                            <li>
                                <a target={'_blank'} href={ds}>{t('Privacy Policy')}</a>
                            </li>
                            <li>
                                <a target={'_blank'} href={agb}>{t('Terms & Conditions')}</a>
                            </li>
                        </ul>
                    </div>
                    <div className={'col-12 col-sm-6'}>
                        <LangChooser noLabel={true}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;