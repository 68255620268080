import shortid from "shortid";

export const uploadFiles = (uid, files) => {
    return (dispatch, getState, getFirebase) => {

        const addedObjects = Object.fromEntries(Object.keys(files).map(key => {
            const x = files[key];
            return [x.name, {
                name: x.name,
                type: x.type,
                id: key
            }]
        }));


        // https://stackoverflow.com/a/47738367/2545832
        const filesPath = `/users/${uid}/${shortid.generate()}/`;
        const firebase = getFirebase();
        dispatch({type: '@no-persist ADDING FILES'});
        return firebase.uploadFiles(filesPath, files)
            .then((e) => {
                return Promise.all(e.map((u) => {
                    const ref = u.uploadTaskSnaphot.ref;
                    return ref.getDownloadURL().then((url) => {
                        addedObjects[ref.name]['ref'] = ref.location.path
                        addedObjects[ref.name]['url'] = url
                    });
                }));
            }).then(() => {
                console.log('Added', addedObjects)
            }).then(() => {
                let toBeAdded = Object.values(addedObjects);
                dispatch({type: 'ADDED_FILES', payload: toBeAdded})
            });
    }
};

export const removeFile = (file) => {
    return (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        const storageRef = firebase.storage().ref();
        storageRef.child(file.ref).delete().then(function () {
            dispatch({type: 'REMOVED_FILES', payload: file.id})
        });
    }

};