const listsById = (state = {}, action) => {
    switch (action.type) {
        case "RETRIEVED_CONTENT":
            return action.payload['listsById'];
        case "ADD_CARD": {
            const {listId, card} = action.payload;
            const cardId = card._id;
            return {
                ...state,
                [listId]: {...state[listId], cards: [...state[listId].cards, cardId]}
            };
        }
        case "MOVE_CARD": {
            const {
                oldCardIndex,
                newCardIndex,
                sourceListId,
                destListId
            } = action.payload;
            // Move within the same list
            if (sourceListId === destListId) {
                const newCards = Array.from(state[sourceListId].cards);
                const [removedCard] = newCards.splice(oldCardIndex, 1);
                newCards.splice(newCardIndex, 0, removedCard);
                return {
                    ...state,
                    [sourceListId]: {...state[sourceListId], cards: newCards}
                };
            }
            // Move card from one list to another
            const sourceCards = Array.from(state[sourceListId].cards);
            const [removedCard] = sourceCards.splice(oldCardIndex, 1);
            const destinationCards = Array.from(state[destListId].cards);
            destinationCards.splice(newCardIndex, 0, removedCard);
            return {
                ...state,
                [sourceListId]: {...state[sourceListId], cards: sourceCards},
                [destListId]: {...state[destListId], cards: destinationCards}
            };
        }
        case "DELETE_CARD_DEFINITELY": {
            const newState = {};
            Object.keys(state).map((k) => {
                newState[k] = state[k];
                newState[k]['cards'] = state[k].cards.filter(i => i !== action.payload)
            });
            return newState
        }
        case "ADD_LIST": {
            const {listId, listTitle} = action.payload;
            return {
                ...state,
                [listId]: {_id: listId, title: listTitle, cards: []}
            };
        }
        case "CHANGE_LIST_TITLE": {
            const {listId, listTitle} = action.payload;
            return {
                ...state,
                [listId]: {...state[listId], title: listTitle}
            };
        }
        case "DELETE_LIST": {
            const {listId} = action.payload;
            const {[listId]: deletedList, ...restOfLists} = state;
            return restOfLists;
        }
        default:
            return state;
    }
};

export default listsById;
