import React, {Component} from "react";
import classNames from "classnames";
import StringField from "../StringField/StringField";
import {withTranslation} from "react-i18next";

const types = {
    'per year': {multiple: 2088},
    'per month': {multiple: 168},
    'per week': {multiple: 40},
    'per day': {multiple: 8},
    'per hour': {multiple: 1}
};


class JobSalaryComponent extends Component {


    render = () => {
        const {props} = this;
        const {i18n} = props;
        const nameAmount = 'attributes-salary-amount';
        const nameUnit = "attributes-salary-unit";
        let unit = props.value[nameUnit] || 'per year';
        const ySalary = (types['per year'].multiple / types[unit].multiple) * props.value[nameAmount];
        return (
            <div className="row">
                <div className="col">
                    <StringField binding={nameAmount} value={props.value} onChange={props.onChange}
                                 placeholder={i18n.t('Salary Placeholder')}
                                 label={i18n.t("Salary for this job")}/>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label htmlFor={nameUnit}>{i18n.t('Salary Unit')}</label>
                        <select className="form-control" name={nameUnit} id={nameUnit}
                                onChange={props.onChange}
                                value={unit}>
                            {Object.keys(types).map((e) => (<option key={e} value={e}>{i18n.t(e)}</option>))}
                        </select>
                        {/*<p className={'small'}>{`The yearly salary would be around ${ySalary} in fulltime`}</p>*/}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(JobSalaryComponent)

